/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    /**
     * Universal click or tap event
     * (replacement for 'click')
     */

    var clickEvent = function () {
        return ('undefined' !== typeof window.touchstart ? 'touchstart' : 'click');
    };

    var winWidth    = $(window).width(),
        mobile      = false,
        mobilePhone = false;

    /**
    * Mobile
    * Window width
    */

    function mobileCheck() {
        if ( '767' >= winWidth ) {
            mobile = true;
        } else {
            mobile = false;
        }

        if ( '575' >= winWidth ) {
            mobilePhone = true;
        } else {
            mobilePhone = false;
        }
    }

    mobileCheck();

    $(window).resize(function() {
        winWidth = $(window).width();
        mobileCheck();
    });

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Delta = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages

                /**
                 * Primary nav
                 */

                // Mobile functions

                var closeSubMenus = function($this) {
                     if ( ! $this ) {
                         $this = $();
                     }

                     $('#menu-primary .expand').not($this).removeClass('active');
                     $('#menu-primary .sub-menu').not($this.parent().children('.sub-menu'))
                         .slideUp();
                },

                closeSearch = function() {
                    $('body').removeClass('mobile-search-is-active');
                    $('#site-nav .search-trigger')
                        .removeClass('active');
                    $('.site-search-container').velocity('slideUp');
                },

                openSearch = function() {
                    $('body').addClass('mobile-search-is-active');
                    $('#site-nav .search-trigger')
                        .addClass('active');
                    $('.site-search-container').velocity('slideDown');
                },

                closePrimaryNav = function() {
                    closeSubMenus();
                    $('body').removeClass('mobile-nav-is-active');
                    $('#site-nav .nav-trigger')
                        .removeClass('active');
                },

                openPrimaryNav = function() {
                    $('body').addClass('mobile-nav-is-active');
                    $('#site-nav .nav-trigger')
                        .addClass('active');
                },

                // Move mobile primary nav
                movePrimaryNav = function() {
                    var $primaryNavContainer = $('.menu-primary-container');

                    if ( mobile ) {
                        $primaryNavContainer.appendTo('.primary-container > .row');
                    } else {
                        $primaryNavContainer.appendTo('#site-nav');
                    }
                };

                // Submenu expand
                $(document).on(clickEvent(), '#menu-primary .expand', function(e) {
                    if ( $(this).hasClass('active') ) {
                        $(this).removeClass('active');

                        $(this).parent().children('.sub-menu')
                            .slideUp();

                    } else {
                        closeSubMenus($(this));

                        $(this).addClass('active');

                        $(this).parent().children('.sub-menu')
                            .slideDown();
                    }
                });

                // close the menu when showing the Buy Now modal dialog
                $('.collapse-class').click(function() {
                    closePrimaryNav();
                });

                // Mobile open/close
                $(document).on(clickEvent(), '#site-nav .nav-trigger', function(e) {
                    e.preventDefault();
                    if ( ! $('body').hasClass('mobile-nav-is-active') ) {
                        openPrimaryNav();
                    }
                });

                $(document).on(clickEvent(), '#site-nav .search-trigger', function(e) {
                    e.preventDefault();
                    if ( ! $('body').hasClass('mobile-search-is-active') ) {
                        openSearch();
                    }
                });

                $(document).on(clickEvent(), 'body.mobile-search-is-active', function(e) {
                    if ( $(e.target) !== $('#site-header .site-search-container') &&
                        ! $(e.target).parents('#site-header .site-search-container').length ) {
                        closeSearch();
                    }
                });

                $(document).on(clickEvent(), 'body.mobile-nav-is-active', function(e) {
                    if ( $(e.target) !== $('#menu-primary') &&
                        ! $(e.target).parents('#menu-primary').length ) {
                        closePrimaryNav();
                    }
                });

                movePrimaryNav();

                $(window).resize(function() {
                    if ( ! mobile ) {
                        $('body')
                            .removeClass('mobile-nav-is-active')
                            .removeClass('mobile-search-is-active');
                        closeSubMenus();
                    }

                    movePrimaryNav();
                });

                if ( ! mobile ) {
                    // Non-mobile menu
                    $(document).on({
                        mouseenter: function(e) {
                            $(this).children('.sub-menu').velocity('slideDown', { duration: 250 });
                            e.stopPropagation();
                        },
                        mouseleave: function(e) {
                            $(this).children('.sub-menu').velocity('slideUp', { duration: 150 });
                            e.stopPropagation();
                        }
                    }, '#menu-primary > li.menu-item');
                }

            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },

        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page

                var owl = $('.owl-carousel');
                owl.owlCarousel({
                    items:         1,
                    autoplay:      true,
                    autoplaySpeed: 1000,
                    autoplayTimeout: 10000,
                    loop:          true,
                    nav:           false,
                    responsive:    {
                        0: {
                            stagePadding: 0
                        },
                        768: {
                            stagePadding: 150
                        }
                    }
                });
                //setTimeout(function() {
                //    owl.trigger('play.owl.autoplay',[5000]);
                //}, 5000);

                var rearrangeHomepageElements = function() {
                    var $containerParent  = $('.row-featured-parent'),
                        $colFeaturedRight = $('.col-featured-right'),
                        $featuredProduct  = $('.col-featured-left'),
                        $shopProducts     = $('.row-shop-products');

                    if ( mobile ) {
                        $featuredProduct.appendTo($containerParent);
                        $shopProducts.prependTo($containerParent);

                    } else {
                        $featuredProduct.prependTo($containerParent);
                        $shopProducts.appendTo($colFeaturedRight);
                    }
                };

                rearrangeHomepageElements();

                $(window).resize(function() {
                    rearrangeHomepageElements();
                });

            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS

            }
        },

        // Too Good For Jelly promo page
        'page_id_1579': {
            init: function() {
                // JavaScript to be fired on the page

            },
            finalize: function() {
                // JavaScript to be fired on the page, after the init JS

                var $cItems = ($(window).width() <= 480) ? 1 : 3;
                var $cPosition = ($(window).width() <= 480) ? 0 : 1;
                $('.owl-carousel.blue-tips-carousel').owlCarousel({
                    'items':  $cItems,
                    'center': true,
                    'startPosition': $cPosition,
                    'loop':   false,
                    'margin': 60,
                    'nav':    true,
                    'dots':   true,
                });
                $('.owl-carousel.orange-tips-carousel').owlCarousel({
                    'items':  $cItems,
                    'center': true,
                    'startPosition': $cPosition,
                    'loop':   false,
                    'margin': 60,
                    'nav':    true,
                    'dots':   true,
                });


                // Overriding the video carosel thumbnail to pull the HD version
                // https://github.com/OwlCarousel2/OwlCarousel2/blob/a797299539fa33d87ad79bfd93e33d8aaeac10ea/dist/owl.carousel.js#L2216
                $.fn.owlCarousel.Constructor.Plugins.Video.prototype.thumbnail = function(target, video) {
                    var tnLink,
            			icon,
            			path,
            			dimensions = video.width && video.height ? 'style="width:' + video.width + 'px;height:' + video.height + 'px;"' : '',
            			customTn = target.find('img'),
            			srcType = 'src',
            			lazyClass = '',
            			settings = this._core.settings,
            			create = function(path) {
            				icon = '<div class="owl-video-play-icon"></div>';

            				if (settings.lazyLoad) {
            					tnLink = '<div class="owl-video-tn ' + lazyClass + '" ' + srcType + '="' + path + '"></div>';
            				} else {
            					tnLink = '<div class="owl-video-tn" style="opacity:1;background-image:url(' + path + ')"></div>';
            				}
            				target.after(tnLink);
            				target.after(icon);
            			};

            		// wrap video content into owl-video-wrapper div
            		target.wrap('<div class="owl-video-wrapper"' + dimensions + '></div>');

            		if (this._core.settings.lazyLoad) {
            			srcType = 'data-src';
            			lazyClass = 'owl-lazy';
            		}

            		// custom thumbnail
            		if (customTn.length) {
            			create(customTn.attr(srcType));
            			customTn.remove();
            			return false;
            		}

            		if (video.type === 'youtube') {
            			path = "//img.youtube.com/vi/" + video.id + "/maxresdefault.jpg";
            			create(path);
            		} else if (video.type === 'vimeo') {
            			$.ajax({
            				type: 'GET',
            				url: '//vimeo.com/api/v2/video/' + video.id + '.json',
            				jsonp: 'callback',
            				dataType: 'jsonp',
            				success: function(data) {
            					path = data[0].thumbnail_large;
            					create(path);
            				}
            			});
            		} else if (video.type === 'vzaar') {
            			$.ajax({
            				type: 'GET',
            				url: '//vzaar.com/api/videos/' + video.id + '.json',
            				jsonp: 'callback',
            				dataType: 'jsonp',
            				success: function(data) {
            					path = data.framegrab_url;
            					create(path);
            				}
            			});
            		}
            	};
                // Override END

                // Synced Carosels for thumbnail navigation
                // reference: https://codepen.io/washaweb/pen/KVRxRW?editors=0010
                var sync1 = $("#tgfj-jelly-breakup-carousel");
                var sync2 = $("#tgfj-jelly-breakup-nav");
                var slidesPerPage = 5; //globaly define number of elements per page
                var syncedSecondary = true;

                function syncPosition(el) {
                  //if you set loop to false, you have to restore this next line
                  //var current = el.item.index;

                  //if you disable loop you have to comment this block
                  var count = el.item.count-1;
                  var current = Math.round(el.item.index - (el.item.count/2) - 0.5);

                  if(current < 0) {
                    current = count;
                  }
                  if(current > count) {
                    current = 0;
                  }
                  //end block

                  sync2
                    .find(".owl-item")
                    .removeClass("current")
                    .eq(current)
                    .addClass("current");
                  var onscreen = sync2.find('.owl-item.active').length - 1;
                  var start = sync2.find('.owl-item.active').first().index();
                  var end = sync2.find('.owl-item.active').last().index();

                  if (current > end) {
                    sync2.data('owl.carousel').to(current, 100, true);
                  }
                  if (current < start) {
                    sync2.data('owl.carousel').to(current - onscreen, 100, true);
                  }
                }

                function syncPosition2(el) {
                  if(syncedSecondary) {
                    var number = el.item.index;
                    sync1.data('owl.carousel').to(number, 100, true);
                  }
                }

                sync2.on("click", ".owl-item", function(e){
                  e.preventDefault();
                  var number = $(this).index();
                  sync1.data('owl.carousel').to(number, 300, true);
                });

                sync1.owlCarousel({
                    'items':    1,
                    'margin':   60,
                    'loop':     true,
                    'video':    true,
                    'lazyLoad': true,
                    'dots':     false,
                    'nav':      true,
                    'navText': ['<svg width="100%" height="100%" viewBox="0 0 11 20"><path style="fill:none;stroke-width: 1px;stroke: #194187;" d="M9.554,1.001l-8.607,8.607l8.607,8.606"/></svg>','<svg width="100%" height="100%" viewBox="0 0 11 20" version="1.1"><path style="fill:none;stroke-width: 1px;stroke: #194187;" d="M1.054,18.214l8.606,-8.606l-8.606,-8.607"/></svg>'],
                    'responsiveRefreshRate' : 200,
                }).on('changed.owl.carousel', syncPosition);

                sync2.on('initialized.owl.carousel', function () {
                  sync2.find('.owl-item').eq(0).addClass('current');
                })
                .owlCarousel({
                    'items':    slidesPerPage,
                    'slideBy':  slidesPerPage,
                    'nav':      false,
                    'margin':   20,
                    'lazyLoad': true,
                    'dots':     false,
                    'responsiveRefreshRate' : 100,
                    'responsive': {
                		0: {
                			'items': 3
                		},
                		479: {
                			'items': 4
                		},
                		768: {
                			'items': 5
                		},
                	}
                }).on('changed.owl.carousel', syncPosition2);

            }
        },

        'nut_butter_faqs': {
            init: function() {

                $('.questions a').on(clickEvent(), function(e) {
                    e.preventDefault();

                    var href = $(this).attr('href'),
                        name = href.replace(/#/, '');

                    $('a[name=' + name + ']').velocity('scroll', {
                        offset:   -20,
                        duration: 500,
                        easing:   'ease-in-out'
                    });
                });

                $('a[href="#top"]').on(clickEvent(), function(e) {
                    e.preventDefault();

                    $('html, body').velocity('scroll', {
                        offset:   0,
                        duration: 300,
                        easing:   'ease-in-out'
                    });
                });

            },
            finalize: function() {
            }
        },

        /* Health facts */
        'health_facts': {
            init: function() {

                var $ctasImg        = $('.cta-image'),
                    $ctasDefaultImg = $ctasImg.data('image-src');

                $('.health-facts-ctas .cta-link').on({
                    mouseenter: function(e) {
                        $ctasImg.css('background-image', 'url(' + $(this).data('image-src') + ')');
                    },
                    mouseleave: function(e) {
                        $ctasImg.css('background-image', 'url(' + $ctasDefaultImg + ')');
                    }
                });

            },
            finalize: function() {
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Delta;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
